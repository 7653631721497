import { Link } from "gatsby"
import React from "react"

import flatartLogo from "../assets/img/flatart-logo.png"
import btniletisim from "../assets/img/rapor.png"
import btnrapor from "../assets/img/iletisim.png"



const Footer = () => (
 
  <footer>
    
    <div className="footer-bottom">
      
  
      <div className="footer-bottom_content">
        <h2>Let’s Meet.</h2>
        <p>
        You can reach us via
           <a href="mailto:hello@theflatart.com"> email</a> or{" "}
          <a href="tel:+905335777239">phone</a> 
        </p>
        <p>or come over to our agency for a cup of coffee!</p>
      </div>
      <div className="footer-bottom_links container">
        <div className="col-md-3 social" style={{ textAlign: "center" }}>
          <img className="footer-logo" alt="Flatart logo" src={flatartLogo} />
        
        </div>

        <div className="col-md-3">
          <h3>Services</h3>
          <ul>
            <li>
              <Link to="/search-engine-optimization/">
              SEO
              </Link>
            </li>
            <li>
              <Link to="/social-media/">Social Media Management</Link>
            </li>
            <li>
              <Link to="/email-marketing/">Email Marketing</Link>
            </li>
            <li>
              <Link to="/web-development/">Web & Mobile Development</Link>
            </li>
            <li>
              <Link to="/ui-ux-design/">UI/UX Design</Link>
            </li>
            <li>
              <Link to="/digital-ads/">Digital Ads</Link>
            </li>
          </ul>
        </div>
        <div className="col-md-3">
          <h3>Solutions</h3>
          <ul>
            <li>
              <Link to="/acquisition/">Acquisition</Link>
            </li>
            <li>
              <Link to="/conversion/">Conversion</Link>
            </li>
            <li>
              <Link to="/analysis/">Analysis</Link>
            </li>
            {/* <li><Link to="#">Lead Generation</Link></li> */}
            {/* <li><Link to="#">CRO</Link></li> */}
          </ul>
        </div>
        <div className="col-md-3">
          <h3>Flatart Agency</h3>
          <ul>
            <li>
              <Link to="/about/">About us</Link>
            </li>
            <li>
              <Link to="/about/">Let's meet</Link>
            </li>
            <li>
              <Link to="/brands/">Brands</Link>
            </li>
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
            <li>
              <Link to="https://lu.ma/d22c053g">AI Meetup</Link>
            </li>
            <li>
              <Link to="/about/#ekip">Team</Link>
            </li>
            <li>
              <Link to="/about/#ik">HR</Link>
            </li>
            {/* <li><Link to="#">Analiz Danışmanlığı</Link></li>
                        <li><Link to="#">Analitik Entegrasyonlar</Link></li>
                        <li><Link to="#">Büyüme Planı</Link></li> */}
          </ul>
        </div>
        <div className="col-md-3">
          <h3>Follow Us</h3>
          <ul>
          <li>
          <a href="https://www.instagram.com/flatart.agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 154.4 140.85"><path class="cls-1" d="M38.79,53.96c.72-3.74,1.39-7.5,3.43-10.83,4.65-7.59,11.93-10.51,20.31-10.71,11.44-.28,22.89-.27,34.33,0,7.77,.19,14.68,2.75,19.41,9.48,3.04,4.33,4.18,9.29,4.26,14.42,.17,10.99,.24,22,0,32.99-.2,8.85-3.28,16.4-11.57,20.9-3.83,2.08-8.02,2.98-12.31,3.03-11.29,.13-22.59,.24-33.88-.02-8.47-.19-15.83-3.05-20.54-10.73-2.04-3.33-2.71-7.08-3.43-10.83V53.96Zm40.97-13.79c-5.61,0-11.22-.11-16.83,.02-8.76,.21-14.15,4.31-15.74,11.85-.34,1.6-.59,3.26-.6,4.89-.05,10.62-.19,21.25,.02,31.87,.22,10.57,5.56,16.22,15.75,16.59,11.51,.42,23.04,.39,34.56,0,8.49-.28,13.54-4.37,15.16-11.7,.29-1.31,.56-2.66,.57-3.99,.06-12.11,.73-24.23-.37-36.32-.65-7.16-5.44-11.84-12.58-12.82-6.63-.91-13.3-.26-19.94-.4Z"/><path class="cls-1" d="M100.86,72.96c-.03,11.41-9.5,20.79-21.13,20.93-11.66,.14-21.34-9.49-21.3-21.2,.03-11.51,9.86-21.15,21.35-20.93,11.79,.22,21.11,9.6,21.08,21.2Zm-7.87-.1c.01-7.26-5.97-13.21-13.32-13.25-7.3-.04-13.37,5.94-13.38,13.18-.01,7.26,5.97,13.21,13.32,13.25,7.3,.04,13.37-5.94,13.38-13.18Z"/><path class="cls-1" d="M101.44,46.22c2.81-.04,5.19,2.29,5.18,5.07-.02,2.66-2.3,4.94-5.02,5.02-2.8,.08-5.24-2.21-5.29-4.97-.05-2.79,2.24-5.08,5.13-5.11Z"/></svg>
              Instagram
            </a>
            </li>
            <li>
          <a href="https://www.linkedin.com/company/flatart-agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path class="cls-1" d="M54.29,40.94c0,4.78-3.04,8.68-8.68,8.68-5.21,0-8.68-3.91-8.68-8.25,0-4.78,3.47-9.12,8.68-9.12s8.68,3.91,8.68,8.68Z"/><rect class="cls-1" x="36.93" y="53.96" width="17.37" height="56.44"/><path class="cls-1" d="M95.97,54.83c-9.12,0-14.33,5.21-16.5,8.68h-.43l-.87-7.38h-15.63c0,4.78,.43,10.42,.43,16.93v37.34h17.37v-30.83c0-1.74,0-3.04,.43-4.34,1.3-3.04,3.47-6.95,8.25-6.95,6.08,0,8.68,5.21,8.68,12.16v29.96h17.37v-32.13c0-16.06-8.25-23.45-19.1-23.45Z"/></svg>
          LinkedIn
            </a>
            </li>
            <li>
          <a href="https://www.facebook.com/flatart.agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path class="cls-1" d="M91.33,44.77h7.24v-15.75h-10.9c-15.44-.01-22.77,9.18-22.77,22.27v12.4h-12.09v14.97h12.09v38.07h18.14v-38.07h12.09l3.43-14.96h-15.53v-10.22c0-4.18,2.29-8.7,8.3-8.7Z"/></svg>
          Facebook
            </a>
            </li>
            <li>
          <a href="https://twitter.com/flatartagency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 154.4 140.85"><path class="cls-1" d="M41.52,97.08c8.79,.77,16.49-1.32,23.61-6.42-1.54-.39-2.85-.66-4.12-1.06-4.77-1.5-8.08-4.66-10.15-9.16-.64-1.39-.5-1.5,1.02-1.48,1.57,.02,3.16,.25,5.02-.45-2.85-.82-5.13-2.08-7.06-3.88-3.1-2.89-4.94-6.44-5.26-10.7-.13-1.72,.09-1.84,1.62-1.14,1.5,.68,3.05,1.13,4.94,1.14-1.17-1.35-2.44-2.32-3.35-3.64-3.59-5.21-4.2-10.73-1.72-16.56,.66-1.55,.92-1.61,2.04-.35,5.5,6.18,12.09,10.71,19.92,13.43,3.45,1.2,7,1.98,10.63,2.35,1.86,.19,2.03,0,1.81-1.83-.77-6.51,2.92-13.31,8.88-16.42,5.98-3.11,13.63-2.23,18.49,2.22,.95,.87,1.85,1.05,3.04,.73,2.54-.7,4.99-1.6,7.31-2.83,.36-.19,.67-.58,1.35-.33-1.11,3.48-3.32,6.14-6.11,8.42,2.75-.33,5.32-1.12,7.98-2.16,.06,.92-.48,1.38-.86,1.87-1.64,2.12-3.46,4.08-5.62,5.67-1.01,.74-1.35,1.6-1.34,2.83,.17,10.94-3.35,20.71-9.89,29.36-7.78,10.28-18.22,16.08-30.98,17.63-10.54,1.28-20.46-.72-29.7-6.01-.34-.19-.66-.41-.98-.63-.11-.08-.19-.21-.53-.62Z"/></svg>
          Twitter
            </a>
            </li>
            <li>
          <a href="https://www.youtube.com/@flatartagency6353" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path d="M94.31,71.33c.04,.2,.05,.39,.04,.59,.04-.2,.04-.4-.04-.59Z"/><path class="cls-1" d="M79.51,38.99c-41.77,0-42.5,3.71-42.5,32.73s.73,32.73,42.5,32.73,42.5-3.71,42.5-32.73-.73-32.73-42.5-32.73Zm14.83,32.93c-.05,.78-.47,1.54-1.29,1.93l-19.18,9.27c-1.54,.75-3.33-.38-3.33-2.09v-18.12c0-1.69,1.76-2.82,3.3-2.11l19.18,8.85c.74,.34,1.16,.98,1.29,1.68,.08,.2,.08,.4,.04,.59Z"/></svg>
          Youtube
            </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom_end">
        <div className="footer-bottom_end-content container">
          <p>
          Flatart 2024 All Rights Reserved.
          </p>
          <ul>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-of-use/">Terms of Use</Link>
            </li>
            <li>
              <a target="_blank" href="https://popupsmart.com">
                Popupsmart
              </a>
            </li>
            {/* <li><Link to="#">Gizlilik Sözleşmesi</Link></li> */}
            {/* <li><Link to="#">Kullanım Koşulları</Link></li> */}
          </ul>
        </div>
      </div>
    </div>
   
  </footer>
)

export default Footer
